//=====================================
//  #ICONS
//  Based on Material Icons
//=====================================

.icon-primary {
  color: $primary-base;
}

// Icon sizing
.icon-14 { font-size: 14px; }
.icon-18 { font-size: 18px; }
.icon-36 { font-size: 36px; }
.icon-48 { font-size: 48px; }

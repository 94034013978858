//=====================================
//  #SUBMENU
//=====================================

.submenu {
  z-index: 2;
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  min-width: 200px;
  background-color: $white;
  padding: 10px 25px 25px;
  border-top: 2px solid $primary-base;
  box-shadow: 0 5px 10px .25px rgba(0, 0, 0, .02);
  margin-top: 5px;
  border: 1px solid $primary-base;

  .submenu__link {
    display: block;
    font-size: rem(16px);
    font-weight: 600;
    padding: 15px 0;
    border-bottom: 1px solid rgba($text-color, .1);
    width: 100%;
    line-height: 1.1;

    &:hover,
    &.active {
      color: $primary-base;
      border-color: $primary-base;
      background-color: transparent;
    }
  }
}

@include mq(medium) {
  .mainmenu__link:hover + .submenu {
    display: block;
  }
  .submenu:hover {
    display: block;
  }
}

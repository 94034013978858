//=====================================
//  #BUTTONS
//=====================================

$button-color:            $primary-base !default;
$button-text-color:       #ffffff !default;
$button-border-radius:    2px !default;
$button-padding:          0 30px !default;
$button-lineheight:       48px !default;


// RESET BUTTON
button,
[type=submit] {
  vertical-align: middle;
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: 0;
  appearance: none;
  user-select: none;

  &:hover,
  &:focus {
    outline: 0;
  }

  &:active {
    background: transparent;
  }
}



// GLOBAL BUTTON
.btn {
  display: inline-block;
  color: $button-text-color;
  background-color: $button-color;
  border-radius: $button-border-radius;
  padding: $button-padding;
  line-height: $button-lineheight;
  text-transform: uppercase;
  font-size: rem(14px);
  font-weight: 700;
  letter-spacing: .1em;
  width: 100%;
  text-align: center;
  transition: .2s ease;
  white-space: nowrap;

  @include mq(large) {
    line-height: 56px;

    &.btn--big {
      line-height: 65px;
    }
  }

  &:hover {
    background-color: lighten($button-color, 10%);
  }



  &--secondary {
    color: $text-color;
    background-color: $secondary-base;

    &:hover {
      background-color: lighten($secondary-base, 15%);
    }

    i {
      color: $text-color;
    }
  }



  &--white {
    color: $text-color;
    background-color: $white;
    box-shadow: 0px 2px 58px 2px rgba(37, 37, 37, 0.15);

    &:hover {
      color: rgba($text-color, .6);
      background-color: $white;
      box-shadow: 0px 22px 58px 2px rgba(37, 37, 37, 0.15);
    }

    i {
      color: $text-color;
    }
  }


  &--ghost {
    color: $text-color;
    background-color: transparent;
    border: 2px solid $primary-base;

    &.btn--secondary { border-color: $secondary-base; }
  }

  &--cookie {
    width: auto;
    margin-top: 15px;
  }

  i {
    margin: -2px 10px 0 0;
    vertical-align: middle;
  }
}

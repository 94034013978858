//=====================================
//  #HEADER
//=====================================

.page-header {
  position: fixed;
  z-index: $header-zindex;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 1px 0.99px 0.01px rgba(37, 37, 37, 0.15);
  min-width: $page-min-width;
  transition: transform .2s ease;

  &--scrolled {
    transform: translate3d(0, -50px, 0);

    @include mq(large) {
      transform: none;

      .page-header__bottom {
        height: 110px;
      }
      .logo {
        transform: scale3d(.7, .7, .7) translate3d(0, -19px, 0);
      }
    }
  }

  &__top {
    background-color: $primary-base;
    height: 55px;
    padding: 8px 0;
    display: flex;
    align-items: center;

    @include mq(large, max-width) {
      .container {
        padding: 0 $gutter-width;
      }
    }

    @include mq(large) {
      height: 72px;

      .container {
        display: flex;
        justify-content: space-between;
      }
    }

  }

  &__bottom {
    height: 90px;
    background-color: $white;
    transition: .2s ease;

    @include mq(large) {
      height: 145px;
    }

    .container,
    .grid {
      height: 100%;
    }
  }
}

.logo {
  display: flex;
  justify-content: center;
  transition: transform .2s ease;
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  width: 174px;
  @include mq(large, max-width) {
    flex: 1;
  }

  a {
    max-width: 110px;
    display: block;
    background-color: $white;
    @include mq(large) {
      max-width: 175px;
      width: inherit;
    }

    img {
      display: block;
      height: 100%;
      width: auto;
    }
  }
}

.main-menu-left,
.main-menu-right {
  flex: 1;
  display: flex;
  justify-content: space-between;
  max-height: 100%;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  li {
    position: relative;
    display: inline-block;
  }

  a {
    position: relative;
    font-weight: $font-weight-bold;
    font-size: rem(16px);
    color: $text-color;
    padding: 10px;
    transition: .2s ease;

    @include mq(large) {
      font-size: rem(14px);
    }

    &:hover,
    &.active {
      background-color: $primary-base;
      color: $white;
    }
  }

  @include mq(large, max-width) {
    display: none;
  }
}

.main-menu-left {
  padding-right: 8%;
}

.main-menu-right {
  padding-left: 8%;
}

//=====================================
//  #HEADER-CONTACT
//=====================================
.header-contact {
  display: flex;
  justify-content: space-between;
  color: $white;

  &-content {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include mq(large) {
      margin-right: 35px;
    }
  }

  &__title {
    font-size: rem(18px);
    font-weight: $font-weight-bold;
    display: block;
    line-height: 1;

    @include mq(large) {
      font-size: rem(24px);
    }
  }

  &__info {
    font-size: rem(12px);
    font-weight: $font-weight-light;
    @include mq(large) {
      font-size: rem(16px);
    }
  }

  .btn {
    font-weight: 600;
    padding: 0 10px;
    line-height: 37px;
    letter-spacing: 0;
    width: auto;

    @include mq(large) {
      line-height: 46px;
      padding: 0 20px;
      font-size: rem(18px);
    }

    i {
      font-size: 18px;
    }
  }
}

//=====================================
//  #HEADER-SECONDARY-MENU
//=====================================
.secondary-menu {
  @include mq(large, max-width) {
    display: none;
  }

  ul {
    margin-top: 11px;
    @include clearfix();

    li {
      float: left;
      margin-left: 20px;
    }
  }

  a {
    padding: 10px;
    color: rgba($white, .7);
    font-weight: $font-weight-light;

    &:hover,
    &.active {
      color: #ffffff;
    }
  }
}

//=====================================
//  #MAIN CONTENT
//=====================================

.page-content {
  padding-top: 145px;
  overflow-x: hidden;

  @include mq(large) {
    padding-top: 217px;
  }
}


// #SEO
.text {
  p {
    margin-top: 30px;
    columns: 400px 2;
    column-gap: 40px;

    @include mq(large) {
      margin-top: 120px;
    }
  }
}


// #TEXT-IMAGE
.text-image {
  + .text-image {
    padding-top: 0;
  }

  &__text {
    padding: 0 50px 0 0;

    p + p {
      margin-top: 1rem;
    }

    ul {
      padding-left: 2.25rem;
    }

    &--right {
      order: 1;

      @include mq(medium) {
        padding: 0 0 0 50px;
      }
    }

    @include mq(medium, max-width) {
      order: 1;
      margin-top: 1rem;
    }

    @include mq(medium) {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
}

//=====================================
//  #ACCORDION
//=====================================

.accordion {
  &__item {
    margin-bottom: -1px;
  }

  &__item-header {
    font-size: rem(18px);
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .02em;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: $gutter-width;
    display: flex;
    justify-content: space-between;
    transition: background-color .2s ease;

    &:hover {
      background-color: rgba($black, .05);
      cursor: pointer;
    }


    .active & {
      i {
        transform: rotate(180deg);
        margin-top: -2px;
      }
    }
  }

  &__item-content {
    max-height: 0;
    overflow: hidden;
    transition: .2s ease;

    ul li {
      margin-bottom: 5px;
    }
  }

  &__item-inner {
    padding: $gutter-width;
    overflow: hidden;
  }
}

//=====================================
//  #SITESEARCH
//=====================================


.site-search {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: $white;
  transition: .2s ease;
  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .site-search__input {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    border: 0;
    max-width: 100%;
    left: 0;
    top: 0;
    padding-left: 40px;
    font-weight: 600;

    @include mq(large) {
      padding-left: 60px;
      font-size: rem(26px);
    }
  }

  .material-icons {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);

    @include mq(large) {
      font-size: rem(28px);
    }
  }

}

.close-search {
  right: 0;
  cursor: pointer;
}

.btn-search {
  text-align: center;
  margin-right: -$gutter-width;
  padding: 0 $gutter-width;
  max-height: 100%;

  @include mq(medium) {
    margin-left: 2rem;
    padding-top: 10px;
  }

  i {
    color: $primary-base;
    font-size: 32px;
    margin-top: -4px;
    min-width: 32px;
  }

  &__title {
    font-size: rem(13px);
    line-height: 1;
    display: block;
    margin-top: -6px;

    @include mq(medium) {
      display: none;
    }
  }

}





//=====================================
//  #SEARCHRESULTS
//=====================================

.searchresults {
  padding: 2rem 0;

  .maintitle {
    display: block;
  }

  @include mq(large) {
    padding: 4rem 0;
  }


  &__list {
    margin-top: 2rem;
  }

  &__list-item {
    display: block;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ccc;
    padding: 1rem;

    h4 {
      font-weight: 600;

      @include mq(large, max-width) {
        font-size: rem(18px);
      }
    }

    small {
      font-weight: 400;
      color: $text-color;
      opacity: .6;
    }

    @include mq(large) {
      padding: 2rem;
    }

    &:hover {
      background-color: #f9f9f9;
    }
  }

}

//=====================================
//  #TESTIMONIALS / MITARBEITERSTIMMEN
//=====================================


.staff-testimonial {
  background-color: $primary-base;
  color: $white;

  .maintitle {
    &::before {
      background-color: $white;
    }
  }

  .swiper-slide {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
  }

  &__swiper {
    margin-top: 30px;

    @include mq(large) {
      margin-top: 80px;
    }
  }

  &__image-holder {
    @include mq(large) {
      max-height: 700px;
      overflow: hidden;
    }
  }

  &__image {
    display: block;
  }

  &__content {
    width: 100%;
    padding: 2rem;
    color: $text-color;
    background-color: $white;
    font-weight: 600;
    position: relative;
    z-index: 2;

    @include mq(large) {
      width: 50%;
      font-size: rem(20px);
      padding: 5rem;
      margin-top: -200px;
      margin-right: 2rem;
    }

    blockquote {
      margin: 0;
      font-style: italic;

      footer {
        margin-top: 1rem;
        font-size: .8em;
      }
    }
  }


  &__pagination {
    text-align: center;
    margin-top: 1rem;

    @include mq(large) {
      position: absolute;
      z-index: 10;
      bottom: 0;
      left: 0;
    }
  }


  &__button-prev,
  &__button-next {
    top: 50%;
    z-index: 1000;
    position: absolute;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    cursor: pointer;
    transition: background-color .25s ease;
    opacity: 1;
    background-color: $primary-base;
    margin-top: -25px;

    @include mq(medium, max-width) {
      display: none;
    }

    .material-icons {
      font-size: 32px;
    }

    &:hover {
      background-color: lighten($primary-base, 20%);
    }

    @include mq(large) {
      width: 65px;
      height: 65px;
      margin-top: -33px;
    }

    &.swiper-button-disabled {
      cursor: default;
      display: none;
    }
  }
  &__button-next {
    right: 1rem;
  }
  &__button-prev {
    left: 1rem;
  }


}

// ===================================================================
//	Name:             Flexbox Grid
//  Description:      grid is mixinbased, generates grid and classes
//                    based on media-queries map
//
//  Usage:
//  create grid-item, can be wrapped inside mq-mixin
// .sematic-element2 {
//    @include make-grid-item(6);
//
//    @include mq(desktop) {
//        @include make-grid-item(12);
//    }
// }
// ===================================================================

// Settings
// ===============================================

$container-max-width:		          1500px !default;
$container-padding:		            2rem !default;
$gutter-width: 				            1rem !default;

// items
$number-of-items:			            12 !default;

$grid-no-gutter:                  false !default; // generates aditional gutterless grid


// Component: grid container mixin
// ===============================================

@mixin make-grid-container() {
  width: 100%;
  max-width: $container-max-width;
  margin: 0 auto;
  padding: 0 $container-padding;

  & & {
    padding: 0;
  }
}

.container {
  @include make-grid-container;
}

.section {
  padding: 40px 0;

  @include mq(large) {
    padding: 100px 0;
  }
}


// Component: grid row mixin
// ===============================================


@mixin make-grid-row {
  display: flex;
  flex: 0 1 auto; // default value
  flex-flow: row wrap; // shorthand for: flex-direction: row; flex-wrap: wrap; items will be pushed to the next line when a row is filled.
  position: relative;
}

.grid {
  @include make-grid-row;

  @if $grid-no-gutter {
    &:not(.grid--no-gutter) {
      margin-left: calc($gutter-width / -2);
      margin-right: calc($gutter-width / -2);

      // falls grid__items keine grid klasse bekommen, sondern über css angesprochen werden
      > *:not(.grid) {
          margin-left: calc($gutter-width / 2);
          margin-right: calc($gutter-width / 2);
      }
    }
  }
  @else {
    margin-left: calc($gutter-width / -2);
    margin-right: calc($gutter-width / -2);
  }

  & > & {
    margin-left: 0;
    margin-right: 0;
  }

  & & {
    margin-top: $gutter-width;
  }

  &--no-margin {
    margin: 0;
  }

  // grid modifiers, helpers and utils
  @if $grid-no-gutter {
    &--no-gutter {
      > [class^="grid__item-"],
      > [class*="grid__item-"] {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &--items-center {
    align-items: center;
  }

  // axis aligment
  &--right {
    justify-content: flex-end;
  }

  &--center {
    justify-content: center;
  }

  &--around {
    justify-content: space-around;
  }

  &--between {
    justify-content: space-between;
  }

  // direction
  &--reverse {
    flex-direction: row-reverse;
  }
}



// Component: auto sizing item mixin
// ===============================================




// Component: grid builder - mobile first
// ===============================================

// define shared item values
%item {
  display: block;
  width: 100%;
  margin-left: calc($gutter-width / 2);
  margin-right: calc($gutter-width / 2);
}

[class^="grid__item"],
[class*="grid__item"] {
  @extend %item;
}

// calculate item-width
@function item-width($i, $items: $number-of-items) {
  // $item-width: percentage($i / $items) - 0.5%;
  $item-width: percentage(calc($i / $items));
  @return $item-width;
}

@mixin make-grid-item($item-size) {

  // warn if number of items is too large
  @if $item-size > $number-of-items {
    @error "Whoops! #{$item-size} is too large. You defined a maximum of #{$number-of-items} items.";
  }

	// generate item and calculate its width
  @else {
    width: calc(#{item-width($item-size)} - #{$gutter-width});

    @if $grid-no-gutter {
    .grid--no-gutter > & {
        width: item-width($item-size);
      }
    }

	}
}


@mixin make-offset-item($item-size) {
  // warn if number of items is too large
  @if $item-size > $number-of-items {
    @error "Whoops! #{$item-size} is too large. You defined a maximum of #{$number-of-items} items.";
  }
	// generate item and calculate its width
  @else {
    margin-left: item-width($item-size);
	}
}

// grid for each breakpoint with gutter
@each $breakpoint, $media-query in $media-queries {
  @include mq($breakpoint) {
    @for $i from 1 through $number-of-items {
      .grid__item-#{$breakpoint}-#{$i} {
        @include make-grid-item($i);
      }
    }
  }
}

// generate offset classes for each breakpoint
@each $breakpoint, $media-query in $media-queries {
  @include mq($breakpoint) {
    @for $i from 1 through $number-of-items {
      .grid__item-offset-#{$breakpoint}-#{$i} {
        @include make-offset-item($i);
      }
    }
  }
}


// Component: helper classes hide/show
// ===============================================

@each $breakpoint, $media-query in $media-queries {
  @include mq($breakpoint, max-width) {
    .hide-on-#{$breakpoint}-down {
      display: none !important;
    }
  }
}

@each $breakpoint, $media-query in $media-queries {
  @include mq($breakpoint) {
    .hide-on-#{$breakpoint}-up {
      display: none !important;
    }
  }
}

//=====================================
//  #HERO
//=====================================

.hero {
  position: relative;
  background-color: #f3f4f6;

  .swiper-slide {
    overflow: hidden;
  }
}


.hero-image-wrapper {
  overflow: hidden;

  .hero-image {
    position: relative;
    z-index: 1;
    display: block;
    height: 300px;
    object-fit: cover;

    @include mq(medium) {
      height: 500px;
    }
    @include mq(large) {
      height: 800px;
    }
  }

  .swiper-lazy-preloader {
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
  }
}

.hero-content {
  position: relative;
  z-index: 2;
  margin-top: -40px;
  background-color: $white;
  padding: 30px 0;
  width: 90%;
  width: calc(100% - 30px);
  margin-bottom: 30px;
  max-width: 905px;

  @include mq(medium) {
    margin-top: -120px;
  }

  @include mq(large) {
    padding: 60px;
    position: absolute;
    left: 0;
    bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    box-shadow: 0 20px 90px 0px rgba(37,37,37,.05)
  }

  .container {
    padding-right: $gutter-width;
  }

  .subtitle {
    @include mq(large, max-width) {
      display: none;
    }
  }

  .hero-description {
    max-height: 100%;
    max-width: 650px;
    margin-top: $gutter-width;

    @include mq(large) {
      font-size: rem(22px);
      padding-left: 2.25rem;
    }
  }

  .btn {
    width: auto;
    margin-top: $gutter-width;
    white-space: nowrap;

    @include mq(large) {
      margin-top: $gutter-width*2;
      margin-left: 2.25rem;
    }
  }
}


// HERO SWIPER ADJUSTMENTS
.hero-controls-wrapper {
  max-width: calc(#{$container-max-width} - 2rem);
  margin: 0 auto;
  position: relative;
}


.hero-button-wrapper {
  display: flex;
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;

  @include mq(large) {
    bottom: 0;
  }
}


.hero-pagination {
  @include mq(large, max-width) {
    display: none;
  }

  position: absolute;
  z-index: 1;
  right: 145px;
  bottom: 0;
  background-color: $white;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  font-size: rem(22px);
  font-weight: 600;
}


// HERO SWIPER CONTEN ANIMATION
.hero--swiper {
  @include mq(large) {
    .hero-content {
      transform: translate3d(0, 100%, 0);
      transition: transform .4s cubic-bezier(0.58, 0.04, 0, 1) .2s;
    }
    .swiper-slide-active .hero-content {
      transform: translate3d(0, 0, 0);
    }
  }
}





//=====================================
//  #HERO SINGLE ADJUSTMENTS
//=====================================

.hero--single {
  padding-bottom: 30px;

  @include mq(large) {
    padding-bottom: 0;
  }

  .hero-image {
    @include mq(large) {
      height: 700px;
    }
  }

  .hero-content {
    margin-bottom: 0;
  }
}

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   http://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */

@media print {
    *,
    *:before,
    *:after,
    p:first-letter,
    div:first-letter,
    blockquote:first-letter,
    li:first-letter,
    p:first-line,
    div:first-line,
    blockquote:first-line,
    li:first-line {
        background: transparent !important;
        color: #000 !important; /* Black prints faster:
                                   http://www.sanbeiji.com/archives/953 */
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    pre {
        white-space: pre-wrap !important;
    }
    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }


    h2,
    h3 {
        page-break-after: avoid;
    }

    // CUSTOM
    .btn-menu,
    .btn-search,
    .hero-controls-wrapper,
    .testimonial-bg-image-holder,
    .page-footer__contact,
    .cta-fixed,
    .cta,
    .btn,
    .swiper-slide-duplicate,
    .page-header__top,
    .partner,
    .accolades  {
      display: none !important;
    }


    .page-header {
      position: static;
      transform: none!important;
    }
    .page-content {
      padding-top: 20px;
    }
    .page-footer {
      page-break-inside: avoid;
    }

    .btn {
      padding: 0;
      display: none;
    }

    .maintitle,
    .subtitle {
      padding: 0;

      &::before {
        display: none;
      }
    }

    .productlist__content .grid > div {
      width: 30%;
    }

    .swiper-wrapper {
      display: block !important;
      transform: none !important;
    }

    .swiper-slide {
      width: 100% !important;
    }

    .swiper-testimonials-wrapper {
      margin-top: 0;
    }

    .maintitle,
    .subtitle,
    img,
    .testimonial__item {
      page-break-inside: avoid;
    }


    .card-branche__content {
      position: inherit;
    }
}

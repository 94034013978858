//=====================================
//  #BUTTON-MENU
//=====================================

.btn-menu {
  padding: 0 $gutter-width;
  margin-left: -$gutter-width;

  @include mq(large) {
    display: none;
  }
}

.btn-menu__content {
  display: inline-block;
  width: 34px;
  height: 21px;
  cursor: pointer;
  position: relative;
  transform: translateZ(0);
  transition: .35s ease;
  user-select: none;
}

.btn-menu__title {
  position: relative;
  font-size: rem(13px);
  line-height: 1;
  display: block;

  span {
    transition: .2s ease;
  }

  .close-title {
    opacity: 0;
    position: absolute;
    left: -7px;
    top: 0;
    transform: translateY(15px);
  }
}

.btn-menu__line {
  position: absolute;
  left: 0;
  top: 0;
  height: 3px;
  width: 100%;
  background: $primary-base;
  border-radius: 2px;
  transition: background-color .35s,
              transform .175s cubic-bezier(.215, .61, .355, 1),
              opacity .175s cubic-bezier(.215, .61, .355, 1);
  transform: translateY(0) translateZ(0);
}

.btn-menu__line--1 { top: 0; }
.btn-menu__line--2 { top: 9px; width: 80%; }
.btn-menu__line--3 { top: 18px; }


// STATE: OPEN
.open {
  .btn-menu__line--1 { transform: translateY(5px) rotate(45deg); }
  .btn-menu__line--2 { opacity: 0; }
  .btn-menu__line--3 { transform: translateY(-13px) rotate(-45deg); }

  .default-title {
    opacity: 0;
    transform: translateY(-15px);
  }

  .close-title {
    opacity: 1;
    transform: translateY(0);
  }
}





//=====================================
//  #MOBILE MENU
//=====================================
.menu-open {
  @include mq(large, max-width) {
    overflow-y: hidden;
  }
}

.mobile-menu-wrapper {
  display: none;
  position: fixed;
  top: 145px;
  left: 0;
  height: calc(100vh - 145px);
  width: 100%;
  z-index: $mobile-menu-zindex;
  background-color: #f3f4f6;
  overflow-y: auto;

  &.open {
    display: block;
  }

  @include mq(large) {
    display: none !important;
  }
}


.mobile-menu {
  padding: $gutter-width*2;

  &__primary,
  &__secondary {
    li {
      margin-bottom: 3px;
    }
    a {
      display: inline-block;
      font-weight: 600;
    }
  }

  &__primary {
    margin-bottom: 38px;

    a {
      color: $text-color;
      font-size: rem(19px);
      padding: 6px 0;
    }
  }

  &__secondary {
    a {
      color: rgba($text-color, .6);
      font-size: rem(17px);
      padding: 4px 0;
    }
  }

}

//=====================================
//  #FOOTER
//=====================================

.page-footer {

  a {
    &:hover {
      opacity: .5;
    }
  }

  &__content {
    border-top: 1px solid rgba($text-color, .15);
    padding: 24px 0 0;
    margin-bottom: $gutter-width*2;

    @include mq(large) {
      padding-top: 60px;
    }
  }
}


//=====================================
//  #FOOTER-CONTACT
//=====================================
.page-footer__contact {
  margin-bottom: 40px;

  @include mq(large) {
    margin-bottom: 120px;
  }
}

.contact-header {
  background-color: $primary-base;
  padding: 40px 0 100px;
  color: $white;

  @include mq(large) {
    padding: 90px 0 250px;
  }

  .maintitle {
    &::before {
      background-color: $white;
    }
  }
}

.contact-content {
  margin-top: -65px;

  @include mq(large) {
    margin-top: -160px;
    padding: 0;
  }
}

.contact-form {
  @include mq(medium) {
    display: flex;
    align-items: stretch;
    box-shadow: 0px 2px 58.2px 1.8px rgba(37, 37, 37, .15);

    .button-wrapper {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      input {
        max-width: 48%;
      }
    }
  }

  @include mq(large) {
    .form-row {
      width: 48%;
      float: left;

      &:nth-child(even) {
        margin-left: 4%;
      }

      &:nth-child(5),
      &:nth-child(6) {
        width: 100%;
        float: none;
        margin-left: 0;
      }

    }


  }

  @include mq(medium, max-width) {
    .button-wrapper {
      margin: 0 -1.5rem -1.5rem;

      input {
        border-radius: 0;
      }
    }
  }

  &__content,
  &__address {
    padding: $gutter-width*1.5;

    @include mq(large) {
      padding: 70px;
    }
  }

  &__content {
    background-color: $white;
    margin-bottom: 20px;

    @include mq(large, max-width) {
      box-shadow: 0px 2px 58.2px 1.8px rgba(37, 37, 37, .15);
    }

    @include mq(medium) {
      width: 60%;
    }

  }

  &__address {
    background: $primary-base url('#{$img}icons/pin-half-icon.svg') no-repeat;
    background-position: right bottom;
    background-size: auto 180px;
    color: $white;

    a {
      color: $white;
    }

    @include mq(medium) {
      width: 40%;
    }
    @include mq(large) {
      background-size: auto 360px;
    }

    .contact-form-title::after {
      background-color: $white;
    }

    .company-address,
    .company-contact__link--phone,
    .company-contact__link--mail,
    .company-contact__link--fax {
      position: relative;
      padding-left: 44px;
      color: $white;
      margin-bottom: $gutter-width;

      &::before {
        font-family: 'Material Icons';
        font-size: rem(20px);
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    .company-address::before {
      content: '\E55F';
    }

    .company-contact__link--phone::before {
      content: '\E0CD';
    }

    .company-contact__link--mail::before {
      content: '\E0BE';
    }

    .company-contact__link--fax::before {
      content: '\E555';
    }

  }
}


.company-contact__link span {
  display: none;

  .page-footer__content & {
    display: inline;
  }
}

.contact-form-title {
  font-weight: 700;
  font-size: rem(19px);
  letter-spacing: .02em;
  position: relative;
  padding-bottom: 17px;
  margin-bottom: 25px;
  text-transform: uppercase;

  @include mq(large) {
    margin-bottom: 50px;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 75px;
    height: 2px;
    background-color: $primary-base;
  }
}


//=====================================
//  #FOOTER-COMPANY-DESC
//=====================================
.footer-company-description {
  margin-bottom: 40px;

  > a {
    display: block;
    max-width: 165px;
    margin-bottom: $gutter-width;

    @include mq(large) {
      max-width: 210px;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      display: inline-block;

      &:not(:last-child)::after {
        content: '-';
      }
    }

    a {
      color: $text-color;
      font-weight: 600;
    }
  }
}


//=====================================
//  #FOOTER-COMPANY-ADDRESS
//=====================================
.company-contact {
  margin-top: $gutter-width;

  &__link {
    display: block;
    color: $text-color;
    font-weight: 600;
  }
}

.company-title {
  display: block;
  font-weight: 600;

  @include mq(large) {
    margin-top: 8px;
  }
}


.footer-menu {
  @include clearfix();
  gap: 1rem;
  display: flex;
  @include mq(large, max-width) {
    margin-bottom: 35px;
  }

  ul {
    width: 50%;
    max-width: 200px;
    float: left;

    a {
      display: inline-block;
      padding: 6px 0;
      font-weight: 600;

      @include mq(large) {
        padding: 8px 0;
        font-size: rem(17px);
      }
    }
  }
}

.page-footer__copy {
  color: rgba($text-color, .85);
  background-color: rgba($text-color, .05);
  text-align: center;
  padding: 22px $gutter-width;
}

//=====================================
//  AUSZEICHNUNGEN
//=====================================

.accolades {

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $gutter-width;
    height: 115px;
  }

  .accolades-image {
    max-height: 100%;
    max-width: 100%;
    width: auto;
  }
}

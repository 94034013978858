//=====================================
//  #PARTNER
//=====================================

.partner {
  background-color: #f3f2f2;
}

.partner-list {
  margin-top: $gutter-width * 3;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    margin-bottom: $gutter-width;
    height: 115px;
    padding: $gutter-width $gutter-width*1.5;
  }

  .partner-image {
    max-height: 100%;
    object-fit: contain;
    width: 100%;
  }
}

.swiper-partner-wrapper {
  position: relative;

  @include mq(large) {
    position: inherit;
    margin-top: calc(120px - 65px);
    padding-left: 1.5rem;
  }

  .swiper-container {
    overflow: visible;
    width: 100%;
    max-width: $container-max-width;
    margin: 0 auto;
    padding: 0;

    @include mq(large, max-width) {
      margin: 0 -.5rem;
    }
  }

  .swiper-slide {
    display: block;
    margin-left: .5rem;
    margin-right: .5rem;
    height: auto;
    @extend .grid__item-mini-9;

    @include mq(small) {
      @extend .grid__item-small-4;
    }

    @include mq(medium) {
      @extend .grid__item-medium-3;
    }
    @include mq(large) {
      @extend .grid__item-large-2;
    }
  }

}

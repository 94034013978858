//=====================================
//  #TYPOGRAPHY
//=====================================


$sans-serif: 'Source Sans Pro', Helvetica, Arial, sans-serif !default;
$base-font-size: 16px; // in pixels. This would result in 22px on desktop
$base-line-height: 1.4; // how large the line height is as a multiple of font size

$leading: $base-line-height * 1rem;
$scale:1.2;

$font-weight-light: 300;
$font-weight-bold: 700;

html {
  font-family: $sans-serif;
  font-size: $base-font-size;
  line-height: $base-line-height;
  color: $text-color;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}


p,li,a {
  margin: 0;
  font-weight: $font-weight-light;
  strong {
    font-weight: $font-weight-bold;
  }
  @include mq(small) {
    font-size: rem(18px);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $sans-serif;
  font-weight: 500;
  margin: 0;
  line-height: 1.1;
}

h1 {
  font-size: rem(60px);
}

h2 {
  font-size: rem(40px);
}

h3 {
  font-size: rem(36px);
}

h4 {
  font-size: rem(28px);
}

h5 {
  font-size: rem(24px);
}

h6 {
  font-size: 1rem;

  @include mq(small) {
    font-size: rem(18px);
  }
}

// #LINKS
a {
  color: $primary-base;
  text-decoration: none;
}

// #LISTS
nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: square;

  @include mq(small) {
    font-size: rem(18px);
  }
}


.maintitle {
  position: relative;
  font-size: rem(30px);
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: .02em;
  font-weight: 700;
  margin-bottom: 10px;

  &::before {
    content: '';
    position: absolute;
    left: (-$gutter-width);
    top: .175em;
    width: 4px;
    height: calc(100% - .3em);
    background-color: $primary-base;

    @include mq(large) {
      left: 0;
      top: .2em;
      width: 7px;
      height: calc(100% - .35em);
    }
  }

  @include mq(large) {
    font-size: rem(56px);
  }
}

.subtitle {
  font-size: rem(27px);
  line-height: 1.15;
  font-weight: 300;

  @include mq(large) {
    font-size: rem(42px);
  }
}

.maintitle,
.subtitle {
  @include mq(large) {
    padding-left: $gutter-width * 2;
  }
}

h1,h2,h3,h4,h5,h6 {
  &:first-letter {
    margin-top: 0!important;
  }
}

.section-text {
  padding: $gutter-width*2 0 0 0;

  @include mq(large) {
    padding: $gutter-width*2 0 0 $gutter-width*2;
  }
}

//=====================================
//  #REASONS
//=====================================

.reasons {

  &__top {
    margin-bottom: 40px;

    @include mq(large) {
      margin-bottom: 120px;
    }
  }
}

.reasons__list {

  &-item {
    margin-bottom: 40px;
  }

  &-icon {
    float: left;
    max-width: 75px;
  }

  &-title {
    font-weight: 600;
    font-size: rem(18px);
    margin-left: 100px;

    @include mq(large) {
      font-size: rem(28px);
    }
  }

  &-text {
    font-size: rem(16px);
    margin-left: 100px;

    @include mq(large) {
      font-size: rem(22px);
    }
  }

  &-link {
    display: inline-block;
    margin-top: .75rem;

    &:hover {
      text-decoration: underline;
    }
  }
}

.btn--reasons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;

  i {
    margin: 0;
  }

  @include mq(large) {
    position: absolute;
    top: 40px;
    right: $gutter-width;
  }
}

/* ==========================================================================
   Cookie
   ========================================================================== */
.cookie--notice {
  background: #fff;
  position: fixed;
  top: 50px;
  left: 50%;
  z-index: 9999999;
  transform: translate(-50%);
  width: 100%;
  max-width: 900px;
  display: block;
  padding: 32px;
  max-height: 87vh;
  overflow: auto;
}

.cookie--notice-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: 100%;
  z-index: 999999;
  background-color: #555;
  opacity: 0.8;
}

.cookie--notice.hidden,
.cookie--notice-overlay.hidden {
  display: none;
}

.cookie--notice h2 {
  font-weight: 800;
  margin-bottom: 1rem;
  color: #000036;
}

.cookie--notice h4 {
  margin: 0;
  font-size: 14px;
  line-height: 1;
  font-weight: 700;
  color: #000036;
}

.cookie--notice p {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #000036;
}

.cookie--notice a {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #52627C;
  text-decoration: none;
  margin: 0px 5px;
  position: relative;
  display: inline-block;
}

.cookie--notice a::before {
  content: "\2192";
  display: inline-block;
  margin: 0 2px 0 0;
  padding: 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  transition: all .2s ease-in-out;
}

.cookie--notice a span {
  border-bottom: 0px solid transparent;
  transition: all .2s ease-in-out;
}

.cookie--notice a:hover span {
  border-bottom: 2px solid #52627C;
}

.cookie--notice a:hover::before {
  transform: translateX(100%);
  opacity: 0;
}

.cookie--notice input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.cookie--notice .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 4px;
  border: 2px solid #666;
}

.cookie--notice label {
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #666;
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
}

.cookie--notice .cookie--checkbox-disabled {
  color: #ccc;
}

.cookie--notice .checkmark::after {
  content: "";
  position: absolute;
  display: none;
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  box-sizing: inherit;
}

.cookie--notice input:checked ~ .checkmark::after {
  display: block;
}

.cookie--notice input:checked ~ .checkmark {
  background-color: #444;
  border-color: #444;
}

.cookie--notice .cookie--checkbox-disabled input:checked ~ .checkmark {
  background-color: #CCC;
  border-color: #CCC;
}

.cookie--options {
  display: flex;
  flex-direction: row;
  margin: 30px 0 20px;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.cookie--accordion {
  display: flex;
  flex-direction: column;
}

.cookie--accordion-trigger {
  line-height: 18px;
  font-size: 14px;
  border: none;
  font-weight: 600;
  background-color: #fff;
  color: #52627C;
  cursor: pointer;
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
}

.cookie--accordion-trigger i {
  display: inline-block;
  transition: 200ms ease all;
  transform: rotate(-180deg);
}

.cookie--accordion-trigger.collapsed i {
  transform: rotate(0deg);
}

.cookie--accordion-content {
  overflow: hidden;
  max-height: 1800px;
  list-style-type: none;
  padding: 0;
  text-align: left;
  margin-top: 20px;
  transition: all 400ms ease-in-out;
}

.cookie--accordion-content.collapsed {
  max-height: 0;
}

.cookie--btn-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.cookie--btn-wrapper button:not(:last-child) {
  margin-right: 16px;
}

.cookie--accept-selection {
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  background-color: #fff;
  border: 2px solid $primary-base;
  color: $primary-base;
  height: 50px;
  min-height: 50px;
  line-height: 24px;
  padding: 11px 30px;
  font-size: 18px;
  font-weight: 700;
  margin-right: 30px;
  transition: 200ms ease all;
}

.cookie--accept-selection:hover {
  background-color: darken($primary-base, 5%);
  color: #fff;
}

.cookie--accept-all {
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  background-color: $secondary-base;
  border: 1px solid $secondary-base;
  color: $text-color;
  height: 50px;
  min-height: 50px;
  line-height: 24px;
  padding: 11px 30px;
  font-size: 18px;
  font-weight: 700;
  transition: 200ms ease all;
}

.cookie--accept-all:hover {
  background-color: lighten($secondary-base, 5%);
  border-color: lighten($secondary-base, 5%);
}

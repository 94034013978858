//=====================================
//  #REFERENCES
//=====================================

.referencelist {

  &__item {
    margin-bottom: 4rem;
  }

  &__content {
    @include mq(small, max-width){
      order: 1;
      margin-bottom: 2rem;
    }
  }

  &__image {
  }

  &__title {
    margin: 1rem 0;

    @include mq(large) {
      margin-top: 2rem 0 1rem;
    }
  }

  &__subtitle {
    text-transform: uppercase;
    font-size: rem(12px);
    font-weight: 700;
  }

  &__text {
    margin-bottom: 2rem;

    p + p {
      margin-top: 1rem;
    }
  }
}


.reference-menu {
  margin: 4rem auto;
  @extend .container;

  &__content {
    border-left: 1px solid #ccc;

    @include mq(large) {
      border: 0;
      border-bottom: 1px solid #ccc;
      display: flex;
    }
  }

  &__item {
    display: block;
    position: relative;
    font-weight: 700;
    color: $text-color;
    padding: 10px;

    &::after {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 2px;
      height: 100%;
      background-color: $primary-base;
    }

    @include mq(large) {
      margin: 0 2px;

      &::after {
        width: 100%;
        height: 2px;
      }
    }

    &:hover,
    &.active {
      color: $primary-base;

      &::after {
        content: '';
      }
    }
  }
}

//=====================================
//  #CARD
//=====================================

.card {
  background-color: $white;
  margin-bottom: $gutter-width*1.5;
  width: 100%;

  &-download {
    .card__image {
      img {
        max-height: unset;
      }
    }
  }

  &__image {
    img {
      max-height:375px;
    }
  }

  &__content {
    padding: $gutter-width;
    color: $text-color;

    @include mq(large) {
      padding: $gutter-width*2;
    }
  }

  &__title {
    font-size: rem(20px);
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .02em;
    word-break: break-word;

    @include mq(large) {
      font-size: rem(26px);
    }
  }

  &__subtitle {
    font-size: rem(16px);
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .02em;
    opacity: .5;
    margin-bottom: 1rem;
  }

  &__text {
    margin-top: 10px;
    margin-bottom: $gutter-width*1.5;
  }

  &__link {
    width: auto;
  }

}

//=====================================
//  #BRANCHEN
//=====================================

.branchen {
  overflow: hidden;

  &__list {
    margin-top: 50px;

    @include mq(large) {
      margin-top: 100px;
    }
  }


  .swiper-branchen {
    overflow: visible;
    width: 100%;
    max-width: $container-max-width;
    margin: 0 auto;
    padding: 0;

    .swiper-slide {
      backface-visibility: hidden;
      transform: translateZ(0);
      display: block;
      margin-left: .5rem;
      margin-right: .5rem;
      @extend .grid__item-mini-11;

      @include mq(small) {
        @extend .grid__item-small-5;
      }

      @include mq(large) {
        @extend .grid__item-large-4;
      }
    }
  }
}

.branchen-button-next,
.branchen-button-prev {
  position: absolute;
  z-index: 10;
  top: 50%;
  margin-top: -32px;

  @include mq(large, max-width) {
    display: none;
  }
}

.branchen-button-next {
  right: 0;
}
.branchen-button-prev {
  left: 0;
}


.card-branche {
  position: relative;
  overflow: hidden;

  &:hover {
    .card-branche__image {
      transform: scale3d(1.1, 1.1, 1.1);
    }
  }

  &__link {
    display: block;
    color: $white;
  }

  &__image {
    position: relative;
    z-index: 1;
    transition: transform .35s cubic-bezier(0.39, 0.18, 0.4, 0.88);

    img {
      height: 355px;
      object-fit: cover;

      @include mq(large) {
        height: 665px;
      }
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &::before {
      background-color: rgba(#dedcd6, .1);
      mix-blend-mode: difference;
    }

    &::after {
      background: rgba(255,255,255,0);
      background-image: -o-linear-gradient(-90deg,transparent 40%,#323232 100%);
      background-image: -moz-linear-gradient(-90deg,transparent 40%,#323232 100%);
      background-image: -ms-linear-gradient(-90deg,transparent 40%,#323232 100%);
      background-image: linear-gradient(-180deg,transparent 40%,#323232 100%);
    }
  }

  &__content {
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 20px;

    @include mq(large) {
      padding: 40px 50px;
    }
  }

  &__title {
    font-size: rem(18px);

    @include mq(large) {
      font-size: rem(30px);
    }
  }

  &__text {
    opacity: .9;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include mq(large) {
      font-size: rem(20px);
    }
  }

}

// ====================================
//  #MEDIA-QUERIES
// ====================================

// set breakpoints for media queries - use px values as they are automatically are converted to rem!
// small devices (portrait phones, e.g. iPhone 4) is the default view
//
//  USAGE EXAMPLE
//
//   .class {
//     @include mq(medium, max-width) {
//       display: none;
//     }
//   }


$media-queries: (
    mini:          360px,
    small:         768px,
    medium:        992px,
    large:        1200px,
    huge:         1400px,
) !default;


// build media queries
@mixin mq($breakpoint, $property: min-width) {

  // check if parameter is valid map key
  @if map-has-key($media-queries, $breakpoint) {

    @if $property == 'max-width' {

      //if max-with mq get map values and substract 1 pixel from mq
      @media ($property: rem(map-get($media-queries, $breakpoint) - 1px )) {
        @content;
      }
    } @else {

      //get map values and convert px to rem
      @media ($property: rem(map-get($media-queries, $breakpoint))) {
        @content;
      }
    }

  }

  // retina media-query
  @else if $breakpoint == 'retina' {

    //autoprefixer adds -webkit-device-ratio
    @media (min-resolution: 1.4dppx), (min-resolution: 140dpi) {
      @content;
    }
  }

  // fallback for px value, if no map key exists e.g. for custom bp
  @else if type-of($breakpoint) == number and unit($breakpoint) == 'px' {

    //convert px to rem
    @media ($property: rem($breakpoint)) {
      @content;
    }
  }

  // if nothing fits -> warn user
  @else {
    @warn "No value can be found for '#{$breakpoint}' in the '$media-queries' map and given parameter doesn't seems to be a px value.";
  }

}

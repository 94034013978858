/* ==========================================================================
   Forms
   ========================================================================== */

// Global
select:focus,
button:focus {
  outline: none;
}

label {
  display: block;
}

[placeholder] {
  text-overflow: ellipsis;

}

::-webkit-input-placeholder {
  transition: .25s;
}

:-moz-placeholder {
  transition: .25s;
}

::-moz-placeholder {
  transition: .25s;
}

:-ms-input-placeholder {
  transition: .25s;
}

// Reset fieldset
fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

// Text inputs
input[type=text],
input[type=password],
input[type=email],
input[type=url],
input[type=time],
input[type=date],
input[type=datetime-local],
input[type=tel],
input[type=number],
input[type=search],
textarea {
  -webkit-appearance: none;

  // General Styles
  background-color: $white;
  color: $text-color;
  border: 2px solid rgba($text-color, .25);
  outline: 0;
  height: 56px;
  width: 100%;
  max-width: 280px;
  font-size: 1rem;
  margin: 0 0 20px 0;
  padding: 0 30px;
  box-shadow: none;
  -webkit-appearance: none;
  transition: .25s ease;
  box-sizing: border-box;
  border-radius: 0;
  font-weight: 700;

  &:hover {
    border-color: $primary-base;
  }

  &:focus,
  &:focus:hover {
    border-color: $primary-base;

    &::-webkit-input-placeholder {
      color: #fff;
    }
    &:-moz-placeholder {
      color: #fff;
    }
    &::-moz-placeholder {
      color: #fff;
    }
    &:-ms-input-placeholder {
      color: #fff;
    }
  }

  &:disabled {
    border: 2px dashed gray;

    &:hover {
      border: 2px dashed gray;
    }
  }
}

// Textarea
textarea {
  min-height: auto;
  height: auto;
  width: 100% !important;
  max-width: auto !important;
  padding: 30px;
}

// Submit
input[type=submit],
input[type=button] {
  @extend .btn;

  &:focus,
  &:active {
    background-color: $primary-base;
  }

  &:disabled {
    background-color: rgba($text-color, .4);

    &:hover {
      background-color: rgba($text-color, .4);
    }
  }
}

// Magnolia Class Styling
.form-wrapper {
  .error label {
    color: #ff0000;
  }
  label {
    position: relative;
    display: inline-block;
    background-color: $white;
    padding: 0 12px;
    margin: 0 20px;
    color: $primary-base;
    font-size: rem(14px);
    bottom: -9px;
  }

  input,
  textarea {
    max-width: 100%;
  }

  fieldset {
    @include clearfix();
  }
}


// CUSTOM STYLES

.custom-checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px 0;
  @include mq(small){
    margin: 20px 0;
  }
}

.custom-control-label {
  bottom: 8px !important;
  margin: 0 0 0 10px !important;
  padding: 0 !important;
  color: $text-color !important;
  a {
    color: $link-color;
    &:hover {
      color: $text-color;
    }
  }
}

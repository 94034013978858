//=====================================
//  #CAREERLIST
//=====================================

.careerlist {
  background-color: #f3f2f2;

  &__content {
    margin-top: 30px;

    @include mq(large) {
      margin-top: 80px;
    }

    [class^="grid__item"] {
      display: flex;
    }
  }
}


.careerdetail {
  background-color: #f3f2f2;

  &__description {
    margin: 2rem 0;

    p + p {
      margin-top: 2rem;
    }
  }

  &__box {
    margin-bottom: 2rem;

    h3 {
      font-size: rem(22px);
      font-weight: 600;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        display: flex;
        margin-bottom: 10px;

        span {
          margin-left: 5px;
        }
      }
    }
  }
}

//=====================================
//  #PRODUKTDETAIL
//=====================================

.productdetail {
  position: relative;
  background-color: #f5f5f6;
  padding: $gutter-width 0;

  @include mq(large) {
    padding: $gutter-width*4 0 $gutter-width*2;

    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 47%;
      height: 100%;
      background-color: $white;
    }
  }

  &__title {
    font-size: rem(36px);
    font-weight: 600;
    color: $primary-base;
    margin-bottom: 1rem;

    @include mq(large) {
      font-size: rem(42px);
      margin: 0;
      letter-spacing: -.04em;
    }
  }

  &__subtitle {
    font-size: rem(21px);
    font-weight: 300;
    opacity: .8;
    margin-bottom: 2rem;

    @include mq(large) {
      font-size: rem(24px);
    }
  }

  &__infotext {
    font-size: rem(17px);
    margin-bottom: 3rem;

    @include mq(large) {
      font-size: rem(20px);
    }
  }


  &__buttonbar {
    margin-top: 50px;

    @include mq(large) {
      margin-top: 80px;
      display: flex;
    }

    .btn {
      max-width: 100%;
    }

    .btn:last-child {
      margin-top: 10px;

      @include mq(large) {
        margin: 0 0 0 34px;
      }
    }
  }


  &__content {
    padding: 2rem 1rem;
    background-color: #fff;
    margin: 0 -1rem;

    @include mq(small) {
      padding: 2rem 5rem;
    }

    @include mq(large) {
      padding: 0;
      margin: 0;
      background-color: transparent;
    }
  }


}

.gallery-col {
  @include mq(large) {
    display: flex;
    align-items: center;
  }
}



// PRODUCT GALLERY
.swiper-image-gallery-wrapper {
  width: 100%;
  margin-bottom: 2rem;

  @include mq(large) {
    margin: 0;
  }
}


// MAIN GALLERY
.product-gallery {
  .swiper-slide {
    width: 100%;
    height: 300px;

    @include mq(small) {
      height: 500px;
    }

    @include mq(large) {
      height: 600px;
    }
  }

  &__imageholder {
    margin: 0;
  }

  &__image {
    display: block;
    object-fit: cover;
    object-position: center;
    height: 300px;

    @include mq(small) {
      height: 500px;
    }

    @include mq(large) {
      height: 600px;
    }
  }

  &__caption {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: $white;
    padding: 5px 10px;
    font-weight: 700;
  }

}


// THUMB GALLERY
.product-gallery-thumbs {
  margin-top: 10px;

  @include mq(large) {
    margin-top: 28px;
  }

  .swiper-slide {
    width: 100px;
    height: 100px;
    opacity: .5;
    transition: opacity .2s ease;

    &-active {
      opacity: 1;
    }
  }

  &__imageholder {
    margin: 0;
  }

  &__image {
    display: block;
    width: 100px;
    height: 100px;
    // object-fit: cover;
  }
}






.gallery-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: rgba(30,31,31,.98);
  visibility: hidden;
  opacity: 0;
  transition: .35s cubic-bezier(0,0,.25,1);

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .swiper-container {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  figure {
    margin: 0;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    max-width: 90%;
    max-height: 90%;
    transform: translate3d(-50%,-50%,0);
  }
}

.modal-button-next,
.modal-button-prev {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  top: 50%;
  height: 100px;
  width: 100px;
  margin-top: -50px;
  color: $white;
  cursor: pointer;

  &:hover {
    opacity: .5;
  }

  i {
    font-size: rem(48px);
  }

  @include mq(medium, max-width) {
    display: none;
  }

  &.swiper-button-disabled {
    display: none;
  }
}

.modal-button-next {
  right: 0;
}
.modal-button-prev {
  left: 0;
}

.modal-pagination {
  position: absolute;
  bottom: 10px;
  left: 0;
  text-align: center;
  width: 100%;
}




// OPEN MODAL BUTTON
.open-modal {
  position: absolute;
  right: 0;
  top: 0;
  width: 65px;
  height: 65px;
  color: $white;
  overflow: hidden;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    right: -20px;
    top: -58px;
    width: 100%;
    height: 200%;
    background-color: $primary-base;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  i {
    position: absolute;
    z-index: 1;
    right: 7px;
    top: 7px;
  }

}





// CLOSE MODAL BUTTON
.close-modal {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  width: 65px;
  height: 65px;
  color: $white;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mq(large) {
    height: 100px;
    width: 100px;

    &:hover {
      opacity: .5;
    }

    i {
      font-size: rem(32px);
    }
  }
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

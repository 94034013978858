//=====================================
//  #CALL-TO-ACTION SECTION
//=====================================

.cta {
  background-color: $secondary-base;
  background-size: auto 100%;
  background-position: center right;
  background-repeat: no-repeat;

  @include mq(large, max-width) {
    padding-bottom: 0;
    margin-bottom: 40px;
  }

  h2 {
    font-weight: 600;
    margin-bottom: 10px;

    @include mq(large, max-width) {
      font-size: rem(22px);
    }
  }

  p {
    @include mq(large) {
      margin-bottom: 50px;
      font-size: rem(26px);
    }
  }
}

.btn--cta {
  @include mq(large, max-width) {
    position: relative;
    bottom: -24px;
  }
}




//=====================================
//  #CALL-TO-ACTION FIXED
//=====================================
.cta-fixed {
  position: fixed;
  z-index: $cta-fixed-zindex;
  right: 0;
  top: 50%;
  transform: translate3d(100%, -50%, 0);

  @include mq(medium, max-width) {
    display: none;
  }

  &__item {
    transform: translate3d(0,0,0);
    height: 80px;
    margin-bottom: 8px;
    transition: .4s cubic-bezier(0.36, 0.68, 0.38, 1);

    &:nth-child(2) {
      transition-delay: .1s;
    }
    &:nth-child(3) {
      transition-delay: .15s;
    }

    .scrolled & {
      transform: translate3d(-80px,0,0);

      &:hover {
        transition-delay: 0s !important;
        transform: translate3d(-100%,0,0);
      }
    }
  }
}

.cta-link {
  position: relative;
  display: flex;
  background-color: darken($primary-base, 10%);
  color: $white;
  text-transform: uppercase;
  font-weight: 700;
  font-size: rem(18px);
  letter-spacing: .02em;
  height: 100%;

  &__icon {
    height: 100%;
    width: 80px;
    padding: 20px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    padding: 0 16px 0 8px;
    transition: .4s cubic-bezier(0.01, 0.6, 0.38, 1);
    opacity: 0;
    transform: translate3d(80px,0,0);

    .cta-link:hover & {
      opacity: 1;
      transform: translate3d(0,0,0);
    }
  }
}

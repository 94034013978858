//=====================================
//  #MAGNOLIA BACKEND ADJUSTMENTS
//=====================================

.edit-mode {

  // Hide elements
  .hero-controls-wrapper,
  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }


  .mgnlEditorBar.mgnlEditor.area.end {
    margin-bottom: 50px !important;
  }

  // HERO
  .hero {
    .swiper-slide-duplicate {
      display: none !important;
    }
    .swiper-wrapper {
      flex-direction: column;
      transform: none !important;
    }
  }

  .hero-content {
    transform: translate3d(0, 0, 0) !important;
  }

  // MAIN
  .page-content {
    padding: 0 50px;
  }


  // HEADER
  .page-header {
    position: static;

    &__top,
    &__bottom {
      height: auto !important;
    }

    &__bottom {
      @include clearfix();

      .grid,
      nav,
      ul {
        display: block;
      }

      nav,
      .logo {
        float: left;
      }
    }
  }
  .header-contact {
    flex-direction: column;
  }
  .logo {
    flex-direction: column;
    padding: 10px;
    a {
      padding: 40px 0 0;
    }
  }

  .cta-fixed {
    position: initial;
    right: inherit;
    transform: none;

    .cta-link:hover {
      transform: none;
    }

    .cta-link__title {
      transform: none;
      opacity: 1;
    }

    &__item {
      transform: none;
    }
  }



  // productCards
  .productlist__content,
  .careerlist__content,
  .team {
    [class*=grid__item],
    [class^=grid__item] {
      float: left;
      display: block;
    }
    .grid {
      display: block;
      @include clearfix();
    }
  }



  // BRANCHEN
  .branchen {
    .swiper-wrapper {
      display: block;
      flex-direction: column;
      transform: none !important;
    }
    .swiper-slide {
      float: left;
    }
  }


}

//=====================================
//  #TESTIMONIALS / KUNDENSTIMMEN
//=====================================

.testimonial {
  position: relative;

  &-inner {
    overflow: hidden;
  }

  &__content {
    position: relative;
    z-index: 1;
  }

  &-text {
    margin-top: 2rem;
    max-width: 590px;

    @include mq(large) {
      font-size: rem(22px);
      margin: 4rem 0 0 2rem;
    }
  }

  &__item {
    background-color: $white;
    box-shadow: 0px 2px 33.25px 1.75px rgba(37, 37, 37, 0.13);
    padding: 1rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mq(large) {
      padding: 3rem;

    }
  }

  &__name {
    font-size: rem(20px);
    font-weight: 600;
    color: $text-color;
    margin: 16px 0 0 20px;

    @include mq(large) {
      font-size: rem(26px);
    }
  }

  &__company {
    display: block;
    font-weight: 400;
    color: $primary-base;

    @include mq(large) {
      font-size: rem(22px);
    }
  }

  &__text {
    color: rgba($text-color, .8);
    margin-top: 2rem;
  }

  &__image-holder {
    overflow: hidden;
    margin: 0;
  }
  &__image {
    display: block;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button-prev,
  &__button-next {
    top: 42%;
    z-index: 99;
    position: relative;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    cursor: pointer;
    transition: background-color .25s ease;
    opacity: 1;
    background-color: $primary-base;
    margin-top: 25px;
    visibility: visible;
    @include mq(medium, max-width) {
      display: none;
    }

    .material-icons {
      font-size: 32px;
    }

    &:hover {
      background-color: lighten($primary-base, 20%);
    }

    @include mq(large) {
      width: 65px;
      height: 65px;
    }

    &.swiper-button-disabled {
      opacity: 0;
      visibility: hidden;
    }
  }
  &__button-next {
    left: 25px;
    @include mq(large) {
      left: calc(25px + 2rem);
    }
  }
  &__button-prev {
    @include mq(large) {
      left: 2rem;
    }
  }
}

.testimonial-bg-image-holder {
  position: absolute;
  right: 0;
  top: -35px;
  z-index: 0;
  height: 880px;
  width: 46%;
  @include mq(large, max-width) {
    top: 0;
    margin: 0 -2rem;
    width: 100%;
    height: 300px;
  }

  .testimonial-bg-image {
    width: 770px;
    height: 880px;
    display: block;
    object-fit: cover;

    @include mq(large, max-width) {
      object-position: top;
      width: 100%;
      height: 300px;
      margin-top: -120px;
    }
  }
}

.swiper-testimonials-wrapper {
  position: relative;
  margin-top: 200px;

  @include mq(large) {
    position: inherit;
    margin-top: calc(120px - 65px);
    padding-left: 1.5rem;
  }

  .swiper-container {
    overflow: visible;
    width: 100%;
    max-width: $container-max-width;
    margin: 0 auto;
    padding: 0;

    @include mq(large, max-width) {
      margin: 0 -.5rem;
    }
  }

  .swiper-slide {
    display: block;
    margin-left: .5rem;
    margin-right: .5rem;
    height: auto;
    @extend .grid__item-mini-11;

    @include mq(small) {
      @extend .grid__item-small-5;
    }

    @include mq(large) {
      @extend .grid__item-large-4;
    }
  }

}

//=====================================
//  #TIMELINE
//=====================================

.timeline {
  background-color: #f5f5f5;

  &__controls {
    position: relative;
    max-width: 680px;
    margin: 0 auto;

    &::after,
    &::before {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      height: 100%;
      width: 40px;
    }

    &::after {
      right: 0;
      background-image: linear-gradient(to left, #f5f5f5, rgba(255, 255, 255, 0));
    }

    &::before {
      left: 0;
      background-image: linear-gradient(to right, #f5f5f5, rgba(255, 255, 255, 0));
    }


    .swiper-container {
      position: relative;
      padding-bottom: 30px;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 19px;
        height: 2px;
        width: 100%;
        background: #ccc;
      }

    }

    .swiper-slide {
      position: relative;
      width: auto;
      font-weight: 700;
      cursor: pointer;
      margin: 0 10px;

      &::after {
        content: '';
        position: absolute;
        bottom: -20px;
        left: 50%;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #ffffff;
        border: 4px solid #ccc;
        margin-left: -10px;
      }

      &:hover,
      &-active {
        color: $primary-base;

        &::after {
          border-color: $primary-base;
          background-color: $primary-base;
        }
      }

    }
  }



  &__main {
    margin-top: 20px;
    .swiper-slide {
    }
  }

  &__content {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 1rem;
  }

  &__image {
    margin-top: 2rem; 
  }



  &-button-next,
  &-button-prev {
    position: absolute;
    top: 12px;
    z-index: 5;
    width: 40px;
    height: 40px;
    background: #f5f5f5;
    border: 5px solid #ccc;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @include mq(large, max-width) {
      display: none;
    }

    &:not(.swiper-button-disabled):hover {
      border-color: $primary-base;
    }

    &.swiper-button-disabled {
      opacity: .4;
    }
  }

  &-button-next {
    right: -50px;
  }
  &-button-prev {
    left: -50px;
  }


}

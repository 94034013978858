//=====================================
//  #GLOBAL
//=====================================

*,
*::after,
*::before {
  box-sizing: border-box;
}

::selection {
  background: rgba($text-color, .2);
}

body {
  background-color: $body-bg-color;
  min-width: $page-min-width;
  -webkit-tap-highlight-color: transparent;
}

img {
  height: auto;
  width: 100%;
}

// Horizontal rules
hr {
  border: 0;
  border-top: 1px solid $text-color;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

// iOS "clickable elements" fix for role="button"
//
// Fixes "clickability" issue (and more generally, the firing of events such as focus as well)
// for traditionally non-focusable elements with role="button"
// see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

[role="button"] {
  cursor: pointer;
}





// #HELPERS
// ------------------------------------

.clearfix {
  @include clearfix;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.center {
  text-align: center;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.full-screen {
  width: 100vw;
  height: 100vh;
}

.horizontal-align {
  display: flex;
  justify-content: center;
}

.invisible {
  visibility: hidden;
  width: 1px;
  height: 1px;
  position: absolute;
  left: -9999px;
}

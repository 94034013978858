//=====================================
//  #SERVICE & SUPPORT
//=====================================

.service-support {
  color: $text-color;
  background-color: $secondary-base;
  background-size: auto 100%;
  background-position: center right;
  background-repeat: no-repeat;

  &__content {
    position: relative;
    right: 0;
    bottom: 0;
  }

  .maintitle {
    &::before {
      background-color: $text-color;
    }
  }

  .service-list {
    margin: $gutter-width 0;
    padding: 0;
    list-style-type: none;
    max-width: 500px;

    @include mq(large) {
      margin: $gutter-width*2 0 0 2rem;
    }

    &__item {
      display: flex;
      margin-bottom: $gutter-width;

      &-text {
        margin-left: 20px;
      }

      @include mq(large) {
        font-size: rem(26px);

        i {
          font-size: rem(32px);
          margin-top: 2px;
        }
      }
    }
  }

  .btn--service {
    @include mq(large) {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

}

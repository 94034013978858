//=====================================
//  #PRODUKTLISTE
//=====================================

.productlist {
  background-color: $primary-base;
  color: $white;

  .maintitle {
    &::before {
      background-color: $white;
    }
  }

  &__content {
    margin-top: 30px;

    @include mq(large) {
      margin-top: 80px;
    }

    [class^="grid__item"] {
      display: flex;
    }
  }
  &.downloadlist {
    background-color: $light-grey;
  }
}

//=====================================
//  #COMPANY
//=====================================

.team {
  background-color: rgba($text-color, .05);

  &__list {
    margin-top: 2rem;

    [class^=grid__item] {
      display: flex;
    }
  }

}


.tel-link,
.mail-link {
  color: $text-color;
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-top: 1px solid #e0e0e0;

  i {
    margin-right: 10px;
  }
}

.mail-link {
  border-bottom: 1px solid #e0e0e0;
}
